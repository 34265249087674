<template>
  <div class="player-box">
    <div :id="playerID"></div>
    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref } from 'vue';

  import PageLoading from '@/components/PageLoading/index.vue';

  import { generateItemKeyIndex } from '@/views/knowledge/index/knowledge-task/generate-data.js';
  import { addAliplayerStyle, addAliplayerScript } from '@/views/material/preview/components/add-aliplayer-sdk.js';
  import { resStatusEnum } from '@/enumerators/http.js';

  import { getVideoPlayAuth } from '@/api/video.js';

  const emit = defineEmits(['player-ready', 'play-ended']);
  const props = defineProps({
    controlsShow: {
      type: Boolean,
      default: false
    }
  });

  const loading = ref(false);
  const { SUCCESS } = resStatusEnum;

  const playerID = `video-player-${generateItemKeyIndex()}`;
  let playerObj = null;
  function playerReadyHandler() {
    emit('player-ready');
  }
  function playEndedHandler() {
    emit('play-ended');
  }
  function destroyPlayer() {
    if (playerObj) {
      playerObj.off('ready', playerReadyHandler);
      playerObj.off('ended', playEndedHandler);
      playerObj.dispose();
      playerObj = null;
    }
  }
  function createPlayer(videoId, playauth, options) {
    options || (options = {
      id: playerID,
      vid: videoId,
      playauth,
      width: '100%',
      height: '100%',
      autoplay: false,
      isLive: false,
      rePlay: false,
      playsinline: true,
      preload: true,
      controlBarVisibility: 'hover',
      useH5Prism: true
    });
    if (!props.controlsShow) {
      options.skinLayout = [
        {
          "name": "H5Loading",
          "align": "cc"
        },
        {
          "name": "errorDisplay",
          "align": "tlabs",
          "x": 0,
          "y": 0
        },
        {
          "name": "infoDisplay"
        },
        {
          "name": "tooltip",
          "align": "blabs",
          "x": 0,
          "y": 56
        },
        {
          "name": "thumbnail"
        },
      ]
    }
    new Aliplayer(options, player => {
      playerObj = player;
      playerObj.on('ready', playerReadyHandler);
      playerObj.on('ended', playEndedHandler);
    });
  }

  defineExpose({
    initVideo(videoID) {
      loading.value = true;
      addAliplayerStyle();
      addAliplayerScript().then(() => {
        getVideoPlayAuth({
          videoIds: videoID
        }).then(({ code, data }) => {
          if (code === SUCCESS) {
            destroyPlayer();
            createPlayer(videoID, data.PlayAuth);
          }
        }).finally(() => {
          loading.value = false;
        });
      }).catch(err => {
        console.log(err);
        loading.value = false;
      });
    },
    initEncryptedVideo(source) {
      addAliplayerStyle();
      addAliplayerScript().then(() => {
        destroyPlayer();
        createPlayer('', '', {
          id: playerID,
          width: '100%',
          height: '100%',
          autoplay: true,
          controlBarVisibility: 'hover',
          preload: true,
          rePlay: false,
          useH5Prism: true,
          isLive: false,
          source: source
        });
      }).catch(err => {
        console.log(err);
        loading.value = false;
      });
    },
    replay() {
      playerObj && playerObj.replay();
    },
    pause() {
      if (playerObj) {
        const status = playerObj.getStatus();
        status !== 'pause' && playerObj.pause();
      }
    }
  });
</script>

<style lang="less" scoped>
  .player-box {
    width: 100%;
    height: 100%;

    :deep(.prism-player .prism-info-display) {
      box-sizing: border-box;
    }
  }
</style>