import env from '@/settings/env.js';
const { publicPath } = env;

const headEl = document.querySelector('head');
const styleFilePath = `${publicPath}lib/aliplayer/2.9.17/style.css`;
const scriptFilePath = `${publicPath}lib/aliplayer/2.9.17/script.js`;
const styleElId = 'aliplayer-style';
const scriptElId = 'aliplayer-script';

export const addAliplayerStyle = () => {
  if (!document.getElementById(styleElId)) {
    const linkEl = document.createElement('link');
    linkEl.id = styleElId;
    linkEl.rel = 'stylesheet';
    linkEl.type = 'text/css';
    linkEl.href = styleFilePath;
    headEl.appendChild(linkEl);
  }
};
export const addAliplayerScript = () => {
  return new Promise((resolve, reject) => {
    if (document.getElementById(scriptElId)) {
      resolve();
    } else {
      const scriptEl = document.createElement('script');
      scriptEl.id = scriptElId;
      scriptEl.type = 'text/javascript';
      scriptEl.src = scriptFilePath;
      scriptEl.onload = () => {
        resolve();
      };
      scriptEl.onerror = () => {
        reject();
      }
      headEl.appendChild(scriptEl);
    }
  });
};